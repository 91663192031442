import React from 'react'
import { EuiFlexGrid, EuiFlexItem, EuiCard, EuiFlexGroup, EuiTitle, EuiText, EuiButton } from '@elastic/eui'
import { useHistory } from "react-router"

export function HitsGrid({ data }) {
    const history = useHistory()

    function handleClick(e) {
        history.push(`/dataset/${e.id}`)
    }

    return (
        <EuiFlexGrid gutterSize="l">
            {data?.results.hits.items.map((hit) => (
                <EuiFlexItem key={ hit.id } grow={ 2 }>
                    <EuiCard
                        onClick={ () => handleClick(hit) }
                        grow={ false }
                        textAlign="left"
                        id={ hit.id }
                        title={ `Dataset Name: ${hit.fields.dataset_name}` }
                        description={ `Description: ${hit.fields.description}` }
                    />
                </EuiFlexItem>
            )) }
        </EuiFlexGrid>
    )
};

export function HitsList({ data }) {
    const history = useHistory()

    function handleClick(e) {
        history.push(`/dataset/${e.id}`)
    }

    return (
        <>
            {data?.results.hits.items.map((hit) => (
                <EuiFlexGroup gutterSize="xl" key={ hit.id }>
                    <EuiFlexItem  >
                        <EuiFlexGroup>
                            <EuiFlexItem grow={ false }>
                                <EuiButton onClick={ () => handleClick(hit) } id={ hit.id }>View Dataset</EuiButton>
                            </EuiFlexItem>
                            <EuiFlexItem grow={ 4 }>
                                <EuiTitle size="xs">
                                    <h6>{ `Dataset Name: ${hit.fields.dataset_name}` }</h6>
                                </EuiTitle>
                                <EuiText grow={ false }>
                                    <p>{ `Project: ${hit.fields.project}` }</p>
                                </EuiText>
                                <EuiText grow={ false }>
                                    <p>{ `Sub-Project: ${hit.fields.sub_project}` }</p>
                                </EuiText>
                                <EuiText grow={ false }>
                                    <p>{ `Collection: ${hit.fields._collection}` }</p>
                                </EuiText>
                                <EuiText grow={ false }>
                                    <p>{ `Activity: ${hit.fields.activity}` }</p>
                                </EuiText>
                                <EuiText grow={ false }>
                                    <p>{ `Vehicle: ${hit.fields.vehicle}` }</p>
                                </EuiText>
                                <EuiText grow={ false }>
                                    <p>{ `Description: ${hit.fields.description}` }</p>
                                </EuiText>
                                <EuiText grow={ false }>
                                    <p>{ `Tags: ${hit.fields.tags.join(' ')}` }</p>
                                </EuiText>
                                <EuiText grow={ false }>
                                    <p>{ `Created: ${hit.fields.createdAt}` }</p>
                                </EuiText>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
            )) }
        </>
    )
};
