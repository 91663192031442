import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import filesize from 'filesize'
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';

import { lineString as t_lineString } from '@turf/helpers'
import length from '@turf/length'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import moment from 'moment'
import MyLocation from '@material-ui/icons/LocationOn'
import SettingsEthernetIcon from '@material-ui/icons/DriveEta'
import HistoryIcon from '@material-ui/icons/History'
import Avatar from '@material-ui/core/Avatar'
import WbSunnyIcon from '@material-ui/icons/WbSunny'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { DataMap } from '../../components/DataMap'
import { getDownloadService, getVizAddress } from '../../utilities/endpoints'
import { fetchVisualizationUrl } from '../../redux/api/fetchVisualizationUrl'
import { fetchCheckVisualizeReady } from '../../redux/api/fetchCheckVisualizeReady'
import { fetchReprocessDataset } from '../../redux/api/fetchReprocessDataset'
import { fetchJobStatus } from '../../redux/api/fetchJobStatus'


import { useHistory } from "react-router-dom";


const DOWNLOADSERVICE = getDownloadService()
const VIZ_ADDRESS = getVizAddress()

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    chipContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}))

export default function DatasetContainer(props) {

    const history = useHistory()
    const goBack = () => {
        history.goBack()
    }
    const classes = useStyles()

    let start_date = "No Start Date"
    let end_date = "No End Date"
    let location = "No Location Data"
    let weatherData = "No Weather Data Available"
    let duration = "No Duration Data"
    let distance = "No Distance Data"
    let datasetSize = "No Dataset Size Data"
    let files = []
    let project = "No Project Data"
    let sub_project = "No Sub-Project Data"
    let collection = "No Collection Data"
    let activity = "No Activity Data"
    let vehicle = "No Vehicle Data"
    let description = "No Description Data"
    let line_to_draw = []
    let num_ROS_messages = 0
    let ROS_topics = []
    let ROS_types = []
    let bounding_box = []
    let feature = []
    let valid_features = false


    const { dataset } = props
 
    const [isLoading, setLoading] = useState(true)
    const [assetExists, setAssetExists] = useState(false)
    const [jobStatus, setJobStatus] = useState(null)


    useEffect(() => {
        const fetchData = async () => {
            const jobStatus = await fetchJobStatus(dataset.dataset._id, 'reprocess')
            setJobStatus(jobStatus)
            setAssetExists(await fetchCheckVisualizeReady(dataset.dataset._id))
            setLoading(false)
        }
        fetchData()
    }, [])


    if (dataset.datasetMetadata != null) {

        try {
            let temp_start_date = moment(dataset.datasetMetadata.extents.temporal.startDate)
            let temp_end_date = moment(dataset.datasetMetadata.extents.temporal.endDate)
            start_date = temp_start_date.toISOString()
            end_date = temp_end_date.toISOString()
            duration = dataset.datasetMetadata.extents.temporal.duration

            if (start_date === null && end_date === null) {
                start_date = "No Start Date"
                end_date = "No End Date"
            }

        } catch (e) {

        }

        try {
            line_to_draw = t_lineString(dataset.datasetMetadata.feature.geometry.coordinates)
            distance = `${(length(t_lineString(dataset.datasetMetadata.feature.geometry.coordinates), { units: 'kilometers' }) * 1000).toFixed(1)} m`
            valid_features = true

        } catch (e) {

        }

        try {
            if (dataset.datasetMetadata.geocodingData != null) {
                location = dataset.datasetMetadata.geocodingData.displayFormat
            }
            if (location == null || location == "" || dataset.datasetMetadata.geocodingData.displayFormat == null) {
                location = "Location is not available"
            }
        } catch (e) {
            location = "Location is not available"
        }

        try {
            if (dataset.datasetMetadata.weatherData != null) {
                weatherData = dataset.datasetMetadata.weatherData.displayFormat
            }
            if (weatherData == null || weatherData == "" || dataset.datasetMetadata.weatherData.displayFormat == null) {
                weatherData = "Weather Not available"
            }
        } catch (e) {
            weatherData = "Weather Not available"
        }

        num_ROS_messages = dataset.datasetMetadata.numMessages
        ROS_types = dataset.datasetMetadata.msgTypes
        ROS_topics = dataset.datasetMetadata.rosTopics
        bounding_box = dataset.datasetMetadata.extents.geospatial.geometry.coordinates
        feature = dataset.datasetMetadata.extents.feature
    }

    const getDatasetSize = function (resources) {
        let size = 0
        resources.map(file => {
            size += file.fileSize
        })

        return filesize(size)
    }

    const getNumberOfFilesInDataset = function (resources) {
        let num = 0

        resources.map(file => {
            num += 1
        })

        return num
    }

    const downloadData = function () {
        window.open(`${DOWNLOADSERVICE}/${dataset.dataset._id}`)
        console.log("Download for dataset with mongo object id " + dataset.dataset._id)
    }

    const downloadBlob = blobId => {
        window.open(`${DOWNLOADSERVICE}/${dataset.dataset._id}/blob/${blobId}`)
        console.log("Download for blob with id " + blobId)
    }
    


    const [value, setValue] = React.useState(1);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const openVisualizer = () => {

        try {
            fetchVisualizationUrl(dataset.dataset._id).then(s3Url => {
                if (s3Url) {
                    window.open(`${VIZ_ADDRESS}?ds=ros1-remote-bagfile&ds.url=${encodeURIComponent(s3Url)}`)
                    console.log("Open visualizer for dataset with mongo object id " + dataset.dataset._id)
                }
            });
        } catch (error) {
            // TODO add error specificity. Auth could be one error source.
            console.log("Unexpected error fetching visualization URL",JSON.stringify(error));
        }
    }

    const reprocessDataset = () => {
        setJobStatus('PENDING')
        try {
            fetchReprocessDataset(dataset.dataset._id).then(success => {
                if (success) {

                    console.log("reprocessDataset: Reprocess dataset message sent for " + dataset.dataset._id)
                }
            });
        } catch (error) {
            // TODO add error specificity. Auth could be one error source.
            console.log("Unexpected error reprocessing dataset",JSON.stringify(error));
        }
    }

    function HomeButton() {
        let history = useHistory();

        function handleClick() {
            history.push("/home");
        }
    }
    if (isLoading) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        )
    }
    return (
        <div className={ classes.root }>
            <button type="button" onClick={goBack} style={{margin: "0 0 20px 0"}}>
                {'\uD83E\uDC78'} Return to Results
            </button>
            <div className={"dataBox"} rowSpacing={1} columnSpacing={{ xs: 1, md: 2, lg: 3 }}>
            <div id={"datasetTitle"}>

                <Button onClick={ () => downloadData() } variant='outlined' color='primary' style={{float: "right", marginInline:10}}>
                    <SaveAltOutlinedIcon /> Download Dataset
                </Button>
                    <div/>
                    {assetExists && jobStatus == 'DONE' ?
                    <Button onClick={()=> openVisualizer()} variant='outlined' color='primary' style={{float:"right"}}>
                        Visualize
                    </Button> : (jobStatus != 'PENDING' && jobStatus != 'DONE' ?
                    <Button onClick={()=> reprocessDataset()} variant='outlined' color='primary' style={{float:"right", display: "none"}}>
                        Reprocess Dataset {jobStatus == 'ERROR' && 'Error. Try Again'}
                    </Button> :
                     <Button disabled color='primary' variant='outlined' style={{float:"right"}}>
                     Reprocess Pending...
                    </Button>)

                    }
                <Typography component="h2" variant="h5" style={{fontWeight: "800", marginBottom: "10px"}}>
                    { dataset.dataset.dataset_name }
                </Typography>
                <Typography style={{width: "calc(100vw - 300px)", display: "inline"}}>
                    Uploaded By: { dataset.dataset.createdBy }
                </Typography>
            </div>

            <br />

            <Grid container id="datasetLocation" spacing={ 3 }>

                <Grid item xs={ 12 } sm container>
                    <Grid item xs container direction="column" spacing={ 2 } className={"pins"}>
                        <Avatar>
                            <MyLocation />
                        </Avatar>
                        <Grid item xs>
                            <Typography variant="body2" color="textSecondary">
                                Location
                            </Typography>
                            <Typography gutterBottom variant="subtitle1">
                                { location }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={ 12 } sm container>
                    <Grid item xs container direction="column" spacing={ 2 } className={"pins"}>
                        <Avatar>
                            <HistoryIcon />
                        </Avatar>
                        <Grid item xs>
                            <Typography variant="body2" color="textSecondary">
                                Duration
                            </Typography>
                            <Typography gutterBottom variant="subtitle1">
                                {/* { start_date }, { end_date } ({ duration } seconds) */}
                                { duration } seconds ({start_date.split('T')[0]})
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={ 12 } sm container>
                    <Grid item xs container direction="column" spacing={ 2 } className={"pins"}>
                        <Avatar>
                            <SettingsEthernetIcon />
                        </Avatar>
                        <Grid item xs>
                            <Typography variant="body2" color="textSecondary">
                                Distance Travelled
                            </Typography>
                            <Typography gutterBottom variant="subtitle1">
                                { distance }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>



                <Grid item xs={ 12 } sm container>
                    <Grid item xs container direction="column" spacing={ 2 } className={"pins"}>
                        <Avatar>
                            <WbSunnyIcon />
                        </Avatar>
                        <Grid item xs>
                            <Typography variant="body2" color="textSecondary">
                                Weather Data
                            </Typography>
                            <Typography gutterBottom variant="subtitle1">
                                { weatherData }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <Grid container id="datasetWindow" spacing={ 3 }>
                <Grid item xs={ 12 } md={ 6 } xl={ 4 } container>
                    <Grid item xs container direction="column" spacing={ 2 }>
                        <Grid item xs>
                            <Card>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Project / Subproject
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        { dataset.dataset.project } / { dataset.dataset.sub_project }
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom>
                                        Collection / Activity / Iteration
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        { dataset.dataset._collection } / { dataset.dataset.activity } / { dataset.dataset.iteration ? dataset.dataset.iteration : "" }
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom>
                                        Activity location
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        { dataset.dataset.activity_location }
                                    </Typography>
                                    {/*<Typography variant="h6" component="h2">
                                        Iteration: { dataset.dataset.iteration ? dataset.dataset.iteration : "" }
                                    </Typography>*/}
                                    <Typography color="textSecondary" gutterBottom>
                                        Vehicle
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        { dataset.dataset.vehicle }
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom>
                                        Description
                                    </Typography>
                                    <Typography className="dataDesc">
                                        { dataset.dataset.description }
                                    </Typography>
                                    {/*<Typography variant="h6" component="h2">
                                        Tags: { dataset.dataset.tags.map(tag => { return tag !== "" ? <Chip label={ tag } /> : null }) }
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Terrain: { dataset.dataset.terrain.map(terrain => { return terrain !== "" ? <Chip label={ terrain } /> : null }) }
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Conditions: { dataset.dataset.conditions.map(conditions => { return conditions !== "" ? <Chip label={ conditions } /> : null }) }
                                    </Typography>*/}
                                </CardContent>
                            </Card>


                        </Grid>

                        {/*}
                        <Grid item xs>
                            <Card>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Data
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Data Size: { getDatasetSize(dataset.resources) }
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        # Files: { getNumberOfFilesInDataset(dataset.resources) }
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        # Files: { getNumberOfFilesInDataset(dataset.resources) }
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Filename(s):
                                    </Typography>
                                    <PerfectScrollbar style={ { XXX/XXX*height: "550px"*XXX/XXX } }>
                                        { dataset.resources.map(file => { return <div><h2>{ file.relativePath ? file.relativePath : file.fileName }</h2></div> }) }
                                    </PerfectScrollbar>
                                </CardContent>
                            </Card>
                        </Grid>
                         */}
                    </Grid>
                </Grid>

                <Grid item xs={ 12 } md={ 6 } xl={ 8 } container>
                    <Grid item container spacing={ 2 }>

                        {/*
                        <Grid item xs={ 12 } md={ 12 } xl={ 6 }>
                            <Card>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Project / Subproject
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        { dataset.dataset.project } / { dataset.dataset.sub_project }
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom>
                                        Collection / Activity / Iteration
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        { dataset.dataset._collection } / { dataset.dataset.activity } / { dataset.dataset.iteration ? dataset.dataset.iteration : "" }
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom>
                                        Activity location
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        { dataset.dataset.activity_location }
                                    </Typography>
                                    {YYY/YYY*<Typography variant="h6" component="h2">
                                        Iteration: { dataset.dataset.iteration ? dataset.dataset.iteration : "" }
                                    </Typography>*YYY/YYY}
                                    <Typography color="textSecondary" gutterBottom>
                                        Vehicle
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        { dataset.dataset.vehicle }
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom>
                                        Description
                                    </Typography>
                                    <Typography className="dataDesc">
                                        { dataset.dataset.description }
                                    </Typography>
                                    {YYY/YYY*<Typography variant="h6" component="h2">
                                        Tags: { dataset.dataset.tags.map(tag => { return tag !== "" ? <Chip label={ tag } /> : null }) }
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Terrain: { dataset.dataset.terrain.map(terrain => { return terrain !== "" ? <Chip label={ terrain } /> : null }) }
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Conditions: { dataset.dataset.conditions.map(conditions => { return conditions !== "" ? <Chip label={ conditions } /> : null }) }
                                    </Typography>*YYY/YYY}
                                </CardContent>
                            </Card>
                        </Grid> */}

                        {/*}
                        <Grid item xs>
                            <Card>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Vehicle
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Name: { dataset.dataset.vehicle }
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs>
                            <Card>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        Description
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        { dataset.dataset.description }
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                         */}

                        {/*}
                        <Grid item xs={ 12 } md={ 12 } xl={ 6 }>
                            <Card>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        ROS Message Types
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Total Messages: { num_ROS_messages }
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Message Types: { ROS_types != undefined ? ROS_types.map(type => { return <Chip label={ type } /> }) : "" }
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Topics: { ROS_topics != undefined ? ROS_topics.map(topic => { return <Chip label={ topic } /> }) : "" }
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Grid>
                        */}


                        <Grid item xs={ 12 } md={ 12 } xl={ 12 }>

                        <div id="dataInfo" >
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    value={value}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}>
                                    <Tab value={1} label="Map" >
                                    </Tab>
                                    <Tab value={2} label="Data Files" >
                                    </Tab>
                                    <Tab value={3} label="Meta-Data" >
                                    </Tab>
                                </Tabs>
                            </Box>
                            <TabPanel value={1}>
                                <Card>
                                    <CardContent>

                                        { valid_features === true &&

                                        <div style={ { height: '550px' } }>
                                            <DataMap
                                                lineFeature={ dataset.datasetMetadata.feature }
                                                bbox={ dataset.datasetMetadata.extents.geospatial.geometry.coordinates }
                                            />
                                        </div>
                                        }

                                    </CardContent>
                                </Card>
                            </TabPanel>
                            <TabPanel value={2}>
                                <Card>
                                    <CardContent>
                                        <Typography color="textSecondary" gutterBottom>
                                            Data
                                        </Typography>
                                        <Typography variant="h6" component="h2">
                                            Data Size: { getDatasetSize(dataset.resources) }
                                        </Typography>
                                        <Typography variant="h6" component="h2">
                                            # Files: { getNumberOfFilesInDataset(dataset.resources) }
                                        </Typography>
                                        <Typography variant="h6" component="h2">
                                            Filename(s):
                                        </Typography>
                                        <PerfectScrollbar style={{ maxHeight: '550px', overflowX: 'hidden' }}>
                                            <List>
                                                {dataset.resources.map(file => (
                                                    <ListItem key={file.uniqueID} divider>
                                                        <ListItemText
                                                            primary={file.relativePath ? file.relativePath : file.fileName}
                                                            secondary={`Size: ${filesize(file.fileSize)}`}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <IconButton edge="end" aria-label="download" onClick={() => downloadBlob(file.uniqueID)}>
                                                                <SaveAltOutlinedIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </PerfectScrollbar>
                                    </CardContent>
                                </Card>

                            </TabPanel>
                            <TabPanel value={3}>
                                    <Card>
                                        <CardContent>
                                            <Typography color="textSecondary" gutterBottom>
                                                ROS Message Types
                                            </Typography>
                                            <Typography variant="h6" component="h2">
                                                Total Messages: { num_ROS_messages }
                                            </Typography>
                                            <Typography variant="h6" component="h2">
                                                Message Types: { ROS_types != undefined ? ROS_types.map(type => { return <Chip label={ type } /> }) : "" }
                                            </Typography>
                                            <Typography variant="h6" component="h2">
                                                Topics: { ROS_topics != undefined ? ROS_topics.map(topic => { return <Chip label={ topic } /> }) : "" }
                                            </Typography>

                                        </CardContent>
                                    </Card>
                            </TabPanel>

                        </TabContext>
                        </div>

                        {/*<h3>TAB NO: {value} clicked!</h3>*/}
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            </div>
        </div>
    )


}