import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

import * as yup from 'yup';
import { Formik, useFormik } from 'formik'
import { Button, Card, TextField, Container, CardHeader, CardContent, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { getHost } from '../../utilities/endpoints'

import './index.css'

const REACT_APP_BACKEND = getHost()

const register = async (args, setAlert) => {
    const urlObject = new URLSearchParams(args);
    const argString = urlObject.toString();

    console.log(`${REACT_APP_BACKEND}/user/register?${argString}`);

    const res = await fetch(`${REACT_APP_BACKEND}/user/register?${argString}`);
    
    console.log(res);

    if (res.ok) {
        setAlert(<Alert severity='success'>Success! You will receive an email with further instructions from a Program Administrator.</Alert>);
        console.error('Error creating a new user');
    } else if (res.status === 409) {
        setAlert(<Alert severity='error'>A user already exists with these credentials.</Alert>);
    } else if (res.status == 503) {
        setAlert(<Alert severity='error'>Failed to send notification to appropriate approver. Please contact support.</Alert>);
    }
    else {
        setAlert(<Alert severity='error'>An error occured creating this user</Alert>);
    }
}

const registerSchema = yup.object({
    firstName: yup
        .string('Enter your first name')
        .required('First name is required'),
    lastName: yup
        .string('Enter your last name')
        .required('Last name is required'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    organization: yup
        .string('Enter your organization')
        .required('Organization is required'),
})

export const Register = () => {
    const [alert, setAlert] = useState(null);
    const location = useLocation(); 
    const queryParams = new URLSearchParams(location.search);
    const commonName = queryParams.get('commonName'); 
    const emailFromCert = queryParams.get('email');

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: emailFromCert || '',
            organization: '',  
            commonName: commonName || '', 
        },
        validationSchema: registerSchema,
        onSubmit: (values) => register(values, setAlert),
    });


    return (
        <div id="background-container" style={{ width: '100vw', height: '100vh' }}> 
            <Container component='main' maxWidth="xs"> 
                <Card sx={{ maxWidth: 345 }}>
                    <CardHeader title='Sign Up for AMIC' />
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container direction={"column"} spacing={3}>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="commonName"
                                        name="commonName"
                                        label="CN"
                                        value={formik.values.commonName}
                                        onChange={formik.handleChange}
                                        error={formik.touched.commonName && Boolean(formik.errors.commonName)}
                                        helperText={formik.touched.commonName && formik.errors.commonName}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email} />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName} />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName} />
                                </Grid>

                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="organization"
                                        name="organization"
                                        label="Organization"
                                        value={formik.values.organization}
                                        onChange={formik.handleChange}
                                        error={formik.touched.organization && Boolean(formik.errors.organization)}
                                        helperText={formik.touched.organization && formik.errors.organization} />
                                </Grid>
                                <Grid item>
                                    <Button color="primary" fullWidth variant="contained" type='submit'>Register</Button>


                                </Grid>
                            </Grid>
                        </form>
                        {alert}
                    </CardContent>
                </Card>
            </Container>
        </div>
)}

export default Register;