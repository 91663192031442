import React, { useState } from "react";

import "./classification-wrapper.css";

export const CLASSIFICATION_COLORS = {
    UNCLASSIFIED: "#3eab1d",
    "TOP SECRET": "#fc7703",
    "TOP SECRET // SCI": "#fcc203",
    CONFIDENTIAL: "#0b4fe0",
    SECRET: "#ed0707",
    "UNCLASSIFIED // CUI": "#3eab1d",
};

export const ClassificationWrapper =
    ({ level, color, children }) => {
        if (color === undefined) {
            color = CLASSIFICATION_COLORS[level];
        }

        return (
            <div className="classified-container">
                <div
                    style={{ backgroundColor: color }}
                    className="classification-ribbon"
                >
                    <span className="label">{level}</span>
                </div>
                <div className="child-container">{children}</div>
                <div
                    style={{ backgroundColor: color }}
                    className="classification-ribbon footer"
                >
                    <span className="label">{level}</span>
                </div>
            </div>
        );
    };

export default ClassificationWrapper;