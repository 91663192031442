import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import mapboxgl from 'mapbox-gl';
import ReactMapboxGl, { Layer, Feature, GeoJSONLayer } from 'react-mapbox-gl';

import centerOfMass from '@turf/center-of-mass';
import { polygon as turfPolygon } from '@turf/helpers';

import './map.css';
import './mapbox-gl.css';

const MapStyles = theme => ({

});

const Mapbox = ReactMapboxGl({
  interactive: true,
  accessToken:"pk.eyJ1IjoiemFtaWMtc2VydmljZSIsImEiOiJjbHNudWk2amIwNTZiMnJscjd2bGhtYzgyIn0.mRY1dm7WPE5X8NAw6bYuIQ"
});

const lineStyle = {
  'line-color': 'red',
  'line-width': 5
}

const lineLayout = {
  'line-join': 'round',
  'line-cap': 'round',
  'symbol-placement': 'line',
  'symbol-spacing': 1,
  'icon-allow-overlap': true,
  'icon-image': 'arrow',
  'icon-size': 0.045,
  'visibility': 'visible'
}



class DataMap extends React.Component {
  constructor(props) {
    super(props);

    // calculate center location of polygon

    const center = centerOfMass(turfPolygon(props.bbox));
    this.state = {
      lng: center.geometry.coordinates[0],
      lat: center.geometry.coordinates[1],
      zoom: 10
    };
  }

  render() {
    const { message, classes, children, lineFeature, bbox } = this.props;
    const { lng, lat, zoom } = this.state;

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Mapbox
          center={[lng, lat]}
          fitBounds={bbox[0]}
          fitBoundsOptions={{ padding: 20 }}
          className={'mapContainer'}
          style={"mapbox://styles/mapbox/satellite-v9"}
          containerStyle={{
            height: '100%',
            width: '100%'
          }}>
          <GeoJSONLayer
            data={{
              "type": "FeatureCollection",
              "features": [lineFeature]
            }}
            linePaint={lineStyle}
            layout={lineLayout}
          />
        </Mapbox>
      </div >
    );
  }
}

DataMap.propTypes = {
  classes: PropTypes.object.isRequired,
  lineFeature: PropTypes.object.isRequired,
  bbox: PropTypes.array.isRequired,
};

DataMap.defaultProps = {
};

export default withStyles(MapStyles)(DataMap);