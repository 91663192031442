// DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government
// agencies and their contractors; Critical Technology; June 2017. Other
// requests for this document shall be referred to U.S. Army CCDC GVSC.
//
// WARNING: This document contains technical data whose export is restricted by
// the Arms Export Control Act (Title 22, U.S.C., Sec 2751, et seq.) or the
// Export Administration Act of 1979 (Title 50, U.S.C., App. 2401 et seq.), as
// amended. Violations of these export laws are subject to severe criminal
// penalties. Disseminate in accordance with provisions of DoD Directive
// 5230.25. DESTRUCTION NOTICE: For unclassified, limited distribution
// documents, destroy by any method that will prevent disclosure of the
// contents or reconstruction of the document.
//
// This material is based upon work supported by the Department of the Army
// under Air Force Contract No. FA8702-15-D-0001. Any opinions, findings,
// conclusions or recommendations expressed in this material are those of the
// author(s) and do not necessarily reflect the views of the Department of the
// Army.
//
// Copyright 2020 Massachusetts Institute of Technology.
//
// The software/firmware is provided to you on an As-Is basis
//
// Delivered to the U.S. Government with Unlimited Rights, as defined in DFARS
// Part 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
// U.S. Government rights in this work are defined by DFARS 252.227-7013 or
// DFARS 252.227-7014 as detailed above. Use of this work other than as
// specifically authorized by the U.S. Government may violate any copyrights
// that exist in this work.

const UPLOAD_DATASET_URL = process.env.REACT_APP_UPLOAD_METADATA


export async function uploadVehicleBagBasedDataset(metadata) {

    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")

    var json_body = JSON.stringify(metadata)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: json_body,
        redirect: 'follow'
    }

    let transaction_id = await fetch(UPLOAD_DATASET_URL, requestOptions)
        .then(response => response.json())
        .then(result => { return result; });

    return transaction_id

}



