import React from 'react';
import { Container, Box, Typography, Button } from '@material-ui/core';


import { getHost } from '../../utilities/endpoints'

import './index.css'

const CertificateInfoPage = () => {
  const handleNext = () => {
    const protocol = window.location.protocol;
    const host = window.location.host;

    const mainDomain = host.replace('register.', '');
    const newUrl = `${protocol}//${mainDomain}/register-form`;

    window.location.href = newUrl;
  };

  return (
    <Container maxWidth="sm">
      <Box my={4} style={{ textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Welcome to AMIC Registration
        </Typography>
        <Typography variant="body1" gutterBottom>
          You will be prompted to select a security certificate to access this service. 
          Please ensure your certificate is ready.
        </Typography>
        <Box my={2} p={2} border={1} borderColor="primary.main" style={{ backgroundColor: '#f5f5f5' }}>
          <Typography variant="body2">
            The certificate prompt will appear in a pop-up window. Please select the appropriate certificate to proceed.
          </Typography>
        </Box>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Next
        </Button>
      </Box>
    </Container>
  );
};

export default CertificateInfoPage;
