// DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government
// agencies and their contractors; Critical Technology; June 2017. Other
// requests for this document shall be referred to U.S. Army CCDC GVSC.
//
// WARNING: This document contains technical data whose export is restricted by
// the Arms Export Control Act (Title 22, U.S.C., Sec 2751, et seq.) or the
// Export Administration Act of 1979 (Title 50, U.S.C., App. 2401 et seq.), as
// amended. Violations of these export laws are subject to severe criminal
// penalties. Disseminate in accordance with provisions of DoD Directive
// 5230.25. DESTRUCTION NOTICE: For unclassified, limited distribution
// documents, destroy by any method that will prevent disclosure of the
// contents or reconstruction of the document.
//
// This material is based upon work supported by the Department of the Army
// under Air Force Contract No. FA8702-15-D-0001. Any opinions, findings,
// conclusions or recommendations expressed in this material are those of the
// author(s) and do not necessarily reflect the views of the Department of the
// Army.
//
// Copyright 2020 Massachusetts Institute of Technology.
//
// The software/firmware is provided to you on an As-Is basis
//
// Delivered to the U.S. Government with Unlimited Rights, as defined in DFARS
// Part 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
// U.S. Government rights in this work are defined by DFARS 252.227-7013 or
// DFARS 252.227-7014 as detailed above. Use of this work other than as
// specifically authorized by the U.S. Government may violate any copyrights
// that exist in this work.

import { inspect } from 'util'

import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Select from 'react-select'
import Paper from '@material-ui/core/Paper'
import ChipInput from 'material-ui-chip-input'
import 'react-dropzone-uploader/dist/styles.css'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { uploadVehicleBagBasedDataset } from '../../../redux/api/fetchVehicleBagBasedDataset'
import { getBackend } from '../../../utilities/endpoints'

// Import React FilePond
import { FilePond } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

const UPLOAD_BAG_URL = process.env.REACT_APP_UPLOAD_BLOB

const VALID_CHAR_LENGTH_BAD_SYMBOL_REGEX = /^([a-z]|[A-Z]|[0-9]|[-(),\s.':_]|(?![!$%^&*+|~=`{}\[\]";<>?\/])){0,200}$/
const VALID_DESCRIPTION_CHAR_AND_LENGTH_REGEX = /^[\s\S]{0,2048}$/
const VALID_BLOB_FILENAME_REGEX = /^([a-z]|[A-Z]|[0-9]|[\s+-=._:\/@]){0,200}$/

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    paper2: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        float: 'right',
    },

    select: {
        margin: theme.spacing(1),
        // display: 'flex',
        flex: 'none',
        // flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
}))

const components = {
    DropdownIndicator: null,
}

const createOption = (label) => ({
    label,
    value: label,
})





const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function UploadFormTheSequel() {
    const classes = useStyles()

    // TODO: DELETE THIS AFTER WE HAVE RETRIEVAL OF THESE FIELDS FROM MONGO FUNCTIONAL - JPM 2021 MAR 08
    const metadataFields = {
        dataset_types: [
            { value: 'Vehicle ROS Bag', label: 'Vehicle ROS Bag' },
            // { value: 'Attribute-Relation File Format (ARFF)', label: 'Attribute-Relation File Format (ARFF)' },
        ],
    }

    const [projectList, setProjectList] = useState([])

    const [datasetType, setDatasetType] = useState(
        metadataFields.dataset_types[0]
    )
    const [project, setProject] = useState('')
    const [sub_project, setSubProject] = useState('')
    const [collection, setCollection] = useState('')
    const [activity, setActivity] = useState('')
    const [activityLocation, setActivityLocation] = useState('')
    const [iteration, setIteration] = useState('')
    const [vehicleConfiguration, setVehicleConfiguration] = useState('')
    const [datasetName, setDatasetName] = useState('')
    const [tags, setTags] = useState([])
    const [terrain, setTerrain] = useState([])
    const [conditions, setConditions] = useState([])
    const [description, setDescription] = useState('')

    const [fieldValidations, setFieldValidations] = useState([])


    const [transaction_id, setTransaction_id] = useState('')
    const [isUploading, setIsUploading] = useState(false)
    const [filesToUpload, setFilesToUpload] = useState([])
    const [validationErrors, setValidationErrors] = useState([])
    const [fileUploadErrors, setFileUploadErrors] = useState([])
    const [allFilesProcessed, setAllFilesProcessed] = useState(false)

    const [open, setOpen] = useState(false)
    const [openError, setOpenError] = useState(false)
    const [openComplete, setOpenComplete] = useState(false)
    const [openProcessing, setOpenProcessing] = useState(false)
    const [openAuthError, setOpenAuthError] = useState(false)


    // Load the project names from the backend
    useEffect(() => {
        async function fetchData() {
            const url = getBackend() + '/api/v1/projects'
            console.log(`fetching ${url}`)

            const result = await fetch(url)
                .then((response) => response.json())
                .then((result) => {
                    return result
                })

            console.log(JSON.stringify(result))

            // Generate the {value: "", label: "" } pairs for the drop-down selection
            if (result.projectNames) {
                let projectNameList = []
                result.projectNames.forEach((value) => {
                    projectNameList.push({ value: value, label: value })
                })
                setProjectList(projectNameList)
            } else {
                setProjectList([
                    {
                        value: 'NONE',
                        label: 'ERROR: failed to load project list',
                    },
                ])
                console.log(
                    'ERROR: UploadForm: Failed to load project list',
                    JSON.stringify(result)
                )
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        validateFiles()
    }, [filesToUpload])

    const validateFiles = () => {
        const validFiles = filesToUpload.filter(file => file.status == 5)
        setAllFilesProcessed(validFiles.length == filesToUpload.length)
    }

    const clearAllFields = (e) => {
        console.log('We would Clear All Fields here.')
        setFilesToUpload([])
        setOpenComplete(false)
    }

    const handleClickOpen = (e) => {
        setOpen(true)
    }

    const handleClose = (e) => {
        setOpen(false)
    }

    const handleAuthClose = (e) => {
        setOpenAuthError(false)
    }

    const handleErrorOpen = (e) => {
        setOpenError(true)
    }

    const handleErrorClose = (e) => {
        setOpenError(false)
    }

    // for reasons i don't have time to understand, the file pond onFileUpdate gets called twice
    var stupid_count = 0

    const getRootOfFilenames = (e) => {
        stupid_count++
        if (stupid_count % 2 != 0) {
            return
        }

        var pondByName = document.getElementsByClassName(
            'filepond--file-info-main'
        )

        e.forEach((x, index) => {
            // console.log(x.file.name, x.file._relativePath)
            // index needs to be by plus 1 because the return of getElementsByClassName starts with a div THEN the spans
            // which house the file name. it's a bit of a gamble doing this in that files somehow may get mixed up, butfset
            // from what i've seen, the drag event variable (e) on the getRootOfFilenames function we're in and the
            // document.getElementsByClassName are in the same order.
            if (x.file._relativePath) {
                pondByName[index + 1].innerText = x.file._relativePath
            }
        })
    }


    const handleUploadSubmitTheSequel = async (e) => {
        let the_files = []

        setValidationErrors([])

        filesToUpload.forEach((file) => {
            the_files.push({
                fileName: file.filename,
                fileSize: file.fileSize,
                relativePath: file.relativePath,
                uniqueID: file.serverId,
                fileExtension: file.fileExtension,
                lastModified: file.file.lastModified,
                lastModifiedDate: file.file.lastModifiedDate,
                fileType: file.file.type ? file.file.type : file.fileExtension,
            })
        })

        var metadata = {
            dataset_type: datasetType.value,
            project: project.value,
            sub_project: sub_project,
            _collection: collection,
            activity: activity,
            activity_location: activityLocation,
            iteration: iteration,
            vehicle: vehicleConfiguration,
            dataset_name: datasetName,
            tags: tags,
            terrain: terrain,
            conditions: conditions,
            description: description,
            files: the_files,
        }

        setOpenProcessing(true)

        try {
            const transaction = await uploadVehicleBagBasedDataset(metadata)
            setOpenProcessing(false)
            console.log(
                'returned from the await: ' + JSON.stringify(transaction)
            )

            if (transaction.status && transaction.status === 403) {
                setOpenAuthError(true)
            } else if (transaction.status && (transaction.status === 400 || transaction.status === 422)){
                setValidationErrors(transaction.errors)
                handleErrorOpen(true)
            }
            
            else {
                // put a pop up here that tells them their dataset was successfully uploaded and that they'll
                // be redirected to the home page

                if (transaction.dataset_id) {
                    setTransaction_id(transaction.dataset_id)
                    setOpenComplete(true)
                }
            }
        } catch (err) {
            // TODO check for auth error
            console.log('UPLOAD DATASET err check', JSON.stringify(err))
        } finally {
            setOpenProcessing(false)
        }
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />

            <div className={classes.paper}>
                <Avatar
                    className={classes.avatar}
                    style={{ background: '#000000' }}
                >
                    <CloudUploadIcon
                        style={{ color: '#fec325', background: '#000000' }}
                    />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Upload a Dataset to the AMIC Data Hub
                    <Divider variant="fullWidth" />
                </Typography>
            </div>

            <Paper className={classes.paper2}>
                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Dataset Type (Required):
                        </Typography>
                    </Grid>

                    <Grid container item xs={8}>
                        <Select
                            className={classes.select}
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                            }}
                            variant="outlined"
                            margin="normal"
                            required
                            error={datasetType.value === 0 ? true : false}
                            helperText="Fill out This Field"
                            autoFocus={true}
                            fullWidth
                            id="dataset_type"
                            label="Dataset Type"
                            autoComplete
                            classNamePrefix="select"
                            onChange={setDatasetType}
                            defaultValue={metadataFields.dataset_types[0]}
                            isClearable={true}
                            isSearchable={true}
                            name="dataset_type"
                            options={metadataFields.dataset_types}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Project (Required):
                        </Typography>
                    </Grid>

                    <Grid container item xs={8}>
                        <Select
                            className={classes.select}
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                            }}
                            variant="outlined"
                            margin="normal"
                            required
                            error={project.value === 0 ? true : false}
                            helperText="Fill out This Field"
                            autoFocus={true}
                            fullWidth
                            id="project"
                            label="Project"
                            autoComplete
                            onChange={setProject}
                            classNamePrefix="select"
                            defaultValue={{
                                label: 'Select a Project...',
                                value: 0,
                            }}
                            isClearable={true}
                            isSearchable={true}
                            name="project"
                            options={projectList}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Sub Project:
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        {/* <CreatableSelect className={classes.select} */}
                        <TextField
                            className={classes.select}
                            // menuPortalTarget={document.body}
                            // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            variant="outlined"
                            margin="normal"
                            // required
                            fullWidth
                            id="sub_project"
                            label="Sub Project"
                            // autoComplete
                            // autoFocus
                            // onChange={setSubProject}
                            onChange={(e) => {setSubProject(e.target.value)
                                setFieldValidations(fieldValidations.filter(x => x != 'sub_project'))
                            }}
                            classNamePrefix="select"
                            // defaultValue={{ label: "Select/Create a Sub-Project...", value: 0 }}
                            // isClearable
                            // isSearchable
                            name="sub project"
                            onBlur={(e) => {
                                if(!VALID_CHAR_LENGTH_BAD_SYMBOL_REGEX.test(e.target.value) && !fieldValidations.includes('sub_project')){
                                    setFieldValidations([...fieldValidations, "sub_project"])
                                
                                } else{
                                setFieldValidations(fieldValidations.filter(x => x != 'sub_project'))
                            
                                }
                            }}
                            error={fieldValidations.includes('sub_project')}
                            helperText = {fieldValidations.includes('sub_project') ? "Invalid Characters" : ""}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Collection:
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        {/* <CreatableSelect className={classes.select} */}
                        <TextField
                            className={classes.select}
                            // menuPortalTarget={document.body}
                            // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            variant="outlined"
                            margin="normal"
                            // required
                            fullWidth
                            id="collection"
                            label="Collection"
                            // autoComplete
                            // autoFocus
                            // onChange={setCollection}
                            onChange={(e) => {
                                setCollection(e.target.value)
                                setFieldValidations(fieldValidations.filter(x => x != 'collection'))
                                    }}
                            classNamePrefix="select"
                            // defaultValue={{ label: "Select/Create a Collection...", value: 0 }}
                            // isClearable
                            // isSearchable
                            name="Collection"
                            onBlur={(e) => {
                                if(!VALID_CHAR_LENGTH_BAD_SYMBOL_REGEX.test(e.target.value) && !fieldValidations.includes('collection')){
                                    setFieldValidations([...fieldValidations, "collection"])
                                
                                } else{
                                setFieldValidations(fieldValidations.filter(x => x != 'collection'))
                            
                                }
                            }}
                            error={fieldValidations.includes('collection')}
                            helperText = {fieldValidations.includes('collection') ? "Invalid Characters" : ""}

                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Activity:
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        {/* <CreatableSelect className={classes.select} */}
                        <TextField
                            className={classes.select}
                            // menuPortalTarget={document.body}
                            // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            variant="outlined"
                            margin="normal"
                            // required
                            fullWidth
                            id="activity"
                            label="Activity"
                            // autoComplete
                            // onChange={setActivity}
                            onChange={(e) => {
                                setActivity(e.target.value)
                                setFieldValidations(fieldValidations.filter(x => x != 'activity'))}}
                            // autoFocus
                            classNamePrefix="select"
                            // defaultValue={{ label: "Select/Create an Activity...", value: 0 }}
                            // isClearable
                            // isSearchable
                            name="Activity"
                            onBlur={(e) => {
                                if(!VALID_CHAR_LENGTH_BAD_SYMBOL_REGEX.test(e.target.value) && !fieldValidations.includes('activity')){
                                    setFieldValidations([...fieldValidations, "activity"])
                                
                                } else{
                                setFieldValidations(fieldValidations.filter(x => x != 'activity'))
                            
                                }
                            }}
                            error={fieldValidations.includes('activity')}
                            helperText = {fieldValidations.includes('activity') ? "Invalid Characters" : ""}


                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Activity Location:
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        {/* <CreatableSelect className={classes.select} */}
                        <TextField
                            className={classes.select}
                            // menuPortalTarget={document.body}
                            // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            variant="outlined"
                            margin="normal"
                            // required
                            fullWidth
                            id="activity_location"
                            label="Activity Location"
                            // autoComplete
                            // onChange={setActivity}
                            onChange={(e) =>{
                                setActivityLocation(e.target.value)
                                setFieldValidations(fieldValidations.filter(x => x != 'activity_location'))
                            }}
                            // autoFocus
                            classNamePrefix="select"
                            // defaultValue={{ label: "Select/Create an Activity...", value: 0 }}
                            // isClearable
                            // isSearchable
                            name="Activity Location"
                            onBlur={(e) => {
                                if(!VALID_CHAR_LENGTH_BAD_SYMBOL_REGEX.test(e.target.value) && !fieldValidations.includes('activity_location')){
                                    setFieldValidations([...fieldValidations, "activity_location"])
                                
                                } else{
                                setFieldValidations(fieldValidations.filter(x => x != 'activity_location'))
                            
                                }
                            }}
                            error={fieldValidations.includes('activity_location')}
                            helperText = {fieldValidations.includes('activity_location') ? "Invalid Characters" : ""}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Iteration/Attempt:
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        <TextField
                            className={classes.select}
                            variant="outlined"
                            margin="normal"
                            // required
                            fullWidth
                            id="iteration"
                            label="Iteration"
                            // autoComplete
                            // autoFocus
                            onChange={(e) => {
                                setIteration(e.target.value)
                                setFieldValidations(fieldValidations.filter(x => x != 'iteration'))
                            }}
                            classNamePrefix="select"
                            // defaultValue={{ label: "Select/Create an Iteration...", value: 0 }}
                            // isClearable
                            // isSearchable
                            name="Iteration"
                            onBlur={(e) => {
                                if(!VALID_CHAR_LENGTH_BAD_SYMBOL_REGEX.test(e.target.value) && !fieldValidations.includes('iteration')){
                                    setFieldValidations([...fieldValidations, "iteration"])
                                
                                } else{
                                setFieldValidations(fieldValidations.filter(x => x != 'iteration'))
                            
                                }
                            }}
                            error={fieldValidations.includes('iteration')}
                            helperText = {fieldValidations.includes('iteration') ? "Invalid Characters" : ""}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Vehicle Configuration:
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        {/* <CreatableSelect className={classes.select} */}
                        <TextField
                            className={classes.select}
                            // menuPortalTarget={document.body}
                            // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            variant="outlined"
                            margin="normal"
                            // required
                            fullWidth
                            id="vechicle"
                            label="Vehicle"
                            name="vechicle"
                            onChange={(e) =>{
                                setVehicleConfiguration(e.target.value)
                                setFieldValidations(fieldValidations.filter(x => x != 'vehicle'))
                            }}
                            // onChange={setVehicleConfiguration}
                            // autoComplete
                            // autoFocus
                            classNamePrefix="select"
                            // defaultValue={{ label: "Select/Create a Vehicle Configuration...", value: 0 }}
                            // isClearable
                            // isSearchable
                            onBlur={(e) => {
                                if(!VALID_CHAR_LENGTH_BAD_SYMBOL_REGEX.test(e.target.value) && !fieldValidations.includes('vehicle')){
                                    setFieldValidations([...fieldValidations, "vehicle"])
                                
                                } else{
                                setFieldValidations(fieldValidations.filter(x => x != 'vehicle'))
                            
                                }
                            }}
                            error={fieldValidations.includes('vehicle')}
                            helperText = {fieldValidations.includes('vehicle') ? "Invalid Characters" : ""}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Dataset Name (Required):
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        <TextField
                            className={classes.select}
                            variant="outlined"
                            margin="normal"
                            // required
                            fullWidth
                            id="dataset"
                            label="Dataset"
                            // autoComplete
                            onChange={(e) => {
                                setDatasetName(e.target.value)
                                setFieldValidations(fieldValidations.filter(x => x != 'dataset'))
                            }}
                            // autoFocus
                            classNamePrefix="select"
                            // defaultValue={{ label: "Select/Create a Dataset...", value: 0 }}
                            // isClearable
                            // isSearchable
                            name="Dataset"
                            onBlur={(e) => {
                                if(!VALID_CHAR_LENGTH_BAD_SYMBOL_REGEX.test(e.target.value) && !fieldValidations.includes('dataset')){
                                    setFieldValidations([...fieldValidations, "dataset"])
                                
                                } else{
                                setFieldValidations(fieldValidations.filter(x => x != 'dataset'))
                            
                                }
                            }}
                            error={fieldValidations.includes('dataset')}
                            helperText = {fieldValidations.includes('dataset') ? "Invalid Characters" : ""}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Tags:
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        <div className={classes.select}>
                            <ChipInput
                                value={tags}
                                color="primary"
                                // defaultValue={[...tags]}
                                variant="outlined"
                                fullWidth
                                id="tags"
                                label="Tags"
                                onAdd={(chip) => {
                                    setTags([...tags, chip])
                                    if(!VALID_CHAR_LENGTH_BAD_SYMBOL_REGEX.test(chip) && !fieldValidations.includes('tags')){
                                        setFieldValidations([...fieldValidations, "tags"])
                                    
                                    } else{
                                    setFieldValidations(fieldValidations.filter(x => x != 'tags'))
                                
                                    }
                                }}
                                onDelete={(chip) => {
                                    setTags(tags.filter(x => x != chip))
                                    setFieldValidations(fieldValidations.filter(x => x != 'tags'))
                                }}
                                error={fieldValidations.includes('tags')}
                                helperText = {fieldValidations.includes('tags') ? "Invalid Characters" : ""}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Terrain:
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        <div className={classes.select}>
                            <ChipInput
                                value={terrain}
                                color="primary"
                                // defaultValue={[...terrain]}
                                variant="outlined"
                                fullWidth
                                id="terrain"
                                label="Terrain"
                                onAdd={(chip) => {
                                    setTerrain([...terrain, chip])
                                    if(!VALID_CHAR_LENGTH_BAD_SYMBOL_REGEX.test(chip) && !fieldValidations.includes('terrain')){
                                        setFieldValidations([...fieldValidations, "terrain"])
                                    
                                    } else{
                                    setFieldValidations(fieldValidations.filter(x => x != 'terrain'))
                                
                                    }
                                }}
                                onDelete={(chip) => {
                                    setTerrain(terrain.filter(x => x != chip))
                                    setFieldValidations(fieldValidations.filter(x => x != 'terrain'))
                                }}
                                error={fieldValidations.includes('terrain')}
                                helperText = {fieldValidations.includes('terrain') ? "Invalid Characters" : ""}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Conditions:
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        <div className={classes.select}>
                            <ChipInput
                                value={conditions}
                                color="primary"
                                // defaultValue={[...terrain]}
                                variant="outlined"
                                fullWidth
                                id="conditions"
                                label="Conditions"
                                onAdd={(chip) => {
                                    setConditions([...conditions, chip])
                                    if(!VALID_CHAR_LENGTH_BAD_SYMBOL_REGEX.test(chip) && !fieldValidations.includes('conditions')){
                                        setFieldValidations([...fieldValidations, "conditions"])
                                    
                                    } else{
                                    setFieldValidations(fieldValidations.filter(x => x != 'conditions'))
                                
                                    }
                                }}
                                onDelete={(chip) => {
                                    setConditions(conditions.filter(x => x != chip))
                                    setFieldValidations(fieldValidations.filter(x => x != 'conditions'))
                                }}
                                error={fieldValidations.includes('conditions')}
                                helperText = {fieldValidations.includes('conditions') ? "Invalid Characters" : ""}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Description:
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        <TextField
                            className={classes.select}
                            variant="outlined"
                            margin="normal"
                            multiline={true}
                            rows={4}
                            // required
                            fullWidth={true}
                            onChange={(e) => {
                                setDescription(e.target.value)
                                setFieldValidations(fieldValidations.filter(x => x != 'description'))
                            }}
                            name="description"
                            // label="Description"
                            type="description"
                            id="description"
                            autoComplete="current-description"
                            onBlur={(e) => {
                                if(!VALID_DESCRIPTION_CHAR_AND_LENGTH_REGEX.test(e.target.value) && !fieldValidations.includes('description')){
                                    setFieldValidations([...fieldValidations, "description"])
                                
                                } else{
                                setFieldValidations(fieldValidations.filter(x => x != 'description'))
                            
                                }
                            }}
                            error={fieldValidations.includes('description')}
                            helperText = {fieldValidations.includes('description') ? "Invalid Characters" : ""}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid
                        container
                        item
                        xs
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Typography component="subtitle" variant="h6">
                            Data (Required):
                        </Typography>
                    </Grid>

                    <Grid container item xs={8}>
                        <div className={classes.form}>
                            <FilePond
                                className={classes.select}
                                name="files"
                                files={filesToUpload}
                                onupdatefiles={(files) => {
                                    getRootOfFilenames(files)
                                    setFilesToUpload(files)
                                }}
                                credits={''}
                                allowMultiple={true}
                                maxFiles={4000}
                                maxParallelUploads={6}
                                onprocessfiles={validateFiles}
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                labelFileProcessingError={(error) => {
                                    console.log(error.body)
                                    return(error.body)
                                }}
                                server={{
                                    url: UPLOAD_BAG_URL,
                                    process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                        // fieldName is the name of the input field
                                        // file is the actual file object to send
                                        const formData = new FormData();
                                        formData.append(fieldName, file, file.name);
                            
                                        const request = new XMLHttpRequest();
                                        request.open('POST', UPLOAD_BAG_URL);
                            
                                        // Should call the progress method to update the progress to 100% before calling load
                                        // Setting computable to false switches the loading indicator to infinite mode
                                        request.upload.onprogress = (e) => {
                                            progress(e.lengthComputable, e.loaded, e.total);
                                        };
                                    
                                        // Should call the load method when done and pass the returned server file id
                                        // this server file id is then used later on when reverting or restoring a file
                                        // so your server knows which file to return without exposing that info to the client
                                        request.onload = () => {
                                           
                                            if (request.status >= 200 && request.status < 300) {
                                                // the load method accepts either a string (id) or an object
                                                load(request.responseText);
                                                                                            
                                            } else {
                                                // Can call the error method if something is wrong, should exit after
                                                error(`Error: Error Status: ${request.status}`);
                                            }
                                        };
                                        
                                        if(!VALID_BLOB_FILENAME_REGEX.test(file.name)){
                                            error("Invalid Characters in Filename")
                                            request.abort()
                                            
                                        } else{
                                            request.send(formData);
                                        }
                                        
                                        // Should expose an abort method so the request can be cancelled
                                        return {
                                            abort: () => {
                                                // This function is entered if the user has tapped the cancel button
                                                request.abort();
                            
                                                // Let FilePond know the request has been cancelled
                                                abort();
                                            },
                                        };
                                    }
                                }}
                                
                               
                            />
                        </div>
                    </Grid>

                    <div className={classes.form}>
                        <Button
                            className={[classes.submit, 'datasetUpload']}
                            // onClick={e => { handleUploadSubmit(e) }}
                            onClick={(e) => {
                                handleUploadSubmitTheSequel(e)
                            }}
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={fieldValidations.length > 0 || datasetName.length == 0 || project.length == 0 || filesToUpload.length == 0 || !allFilesProcessed}
                        >
                            Upload Dataset
                        </Button>
                    </div>
                </Grid>
            </Paper>

            <div>
                <Dialog
                    open={openError}
                    TransitionComponent={Transition}
                    // keepMounted
                    onClose={(e) => {
                        handleErrorClose(e)
                    }}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {'Dataset Upload Error'}
                    </DialogTitle>
                    <DialogContent id="upload-dialog">
                        <div>
                            <p>
                                Error with Dataset Upload: 
                            </p>
                            <p>Errors: </p>
                            {validationErrors.map((error) => {
                                return (
                                    <div>
                                        <ul>
                                            <strong>{error.msg}:</strong> {error.param} = {error.value}
                                        </ul>
                                    </div>
                                )
                            })}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                handleErrorClose(e)
                            }}
                            color="primary"
                        >
                            Dismiss
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={openComplete}
                    TransitionComponent={Transition}
                    // keepMounted
                    // onClose={history.push('/')}

                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {'Dataset Upload Complete'}
                    </DialogTitle>
                    <DialogContent id="upload-dialog">
                        <div>
                            <p>
                                Your Dataset has been successfully uploaded. You
                                can continue to upload datasets. Refresh the
                                page to clear all fields or modify files/fields
                                to create a new dataset to upload.{' '}
                            </p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                clearAllFields(e)
                            }}
                            color="primary"
                        >
                            Dismiss
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Dialog
                    open={openProcessing}
                    TransitionComponent={Transition}
                    // keepMounted
                    // onClose={history.push('/')}

                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {'Dataset Upload Processing'}
                    </DialogTitle>
                    <DialogContent id="upload-dialog">
                        <div>
                            <p>Your Dataset is being uploaded. Please wait.</p>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={openAuthError}
                    TransitionComponent={Transition}
                    // keepMounted

                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {'Dataset Upload Error'}
                    </DialogTitle>
                    <DialogContent id="upload-dialog">
                        <div>
                            <p>Uploading to that Project is not authorized</p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => handleAuthClose(e)}
                            color="primary"
                        >
                            Dismiss
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Container>
    )
}
