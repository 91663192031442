import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import FeedbackIcon from '@material-ui/icons/Feedback'
import { Typography } from '@material-ui/core'
import jQuery from 'jquery'

// followed structure similar to the OP in https://community.atlassian.com/t5/Confluence-questions/Jira-Issue-Collector-window-not-opening-from-custom-trigger/qaq-p/1160172

// need this to make jquery available for the jira batch script
window.jQuery = window.$ = jQuery
window.ATL_JQ_PAGE_PROPS = {
    "triggerFunction": function (showCollectorDialog) {
        //Requires that jQuery is available!
        // .click() is deprecated, switch to on("click", f)
        jQuery("#feedbackTrigger").on("click", function (e) {
            e.preventDefault()
            showCollectorDialog()
        })
    }
}

// add the two scripts from jira issue collector page
const setCollector = () => {
    const appElement = document.querySelector('body')
    if (appElement) {
        const script1 = document.createElement('script')
        script1.type = 'text/javascript'
        script1.src = "https://jira.di2e.net/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-g17gop/813001/65f3dd454013999e25668f47db9d1c3a/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=72312904"
        appElement.appendChild(script1)

        const script2 = document.createElement('script')
        script2.type = 'text/javascript'
        script2.src = "https://jira.di2e.net/s/395863c05f9358bd80bcc5aaff35df28-T/-g17gop/813001/65f3dd454013999e25668f47db9d1c3a/4.0.3/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=72312904"
        appElement.appendChild(script2)
    }
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        position: 'fixed',
        // the bottom classification wrapper is 30px high
        bottom: 30,
        zIndex: 9999,
        color: 'inherit',
        alignItems: 'center'
    },
})

export default function SimpleBottomNavigation() {
    const classes = useStyles()
    const mailtoLink = "mailto:AmicDatahubDevs@mitlincolnlaboratory.onmicrosoft.com?subject=Data%20Hub%20Feedback&body=If%20this%20is%20a%20bug%20or%20feature%20request%2C%20please%20use%20one%20of%20the%20below%20templates.%20Otherwise%2C%20please%20feel%20free%20to%20provide%20general%20feedback.%20%0A%0A---------------------------------%0ABug%0A%0AConditions%20to%20Reproduce%3A%20%5BPlease%20provide%20some%20information%20on%20how%20the%20bug%20was%20encountered%5D%0A%0AExpected%20Behavior%3A%20%5BPlease%20tell%20us%20what%20you%20expected%20the%20result%20of%20your%20actions%20to%20be%5D%0A%0AActual%20Behavior%3A%20%5BPlease%20tell%20us%20what%20the%20actual%20response%20from%20the%20data%20hub%20was.%5D%0A%0AContextual%20Information%3A%20%5BPlease%20provide%20any%20notes%20or%20contextual%20information%20here%5D%0A%0A---------------------------------%0AFeature%0A%0AName%3A%20%5BGive%20a%20useful%20name%20to%20your%20feature%5D%0A%0AProblem%20Statement%3A%20%5BPlease%20let%20us%20know%20what%20problem%20you%20are%20trying%20to%20solve%20so%20that%20we%20can%20identify%20if%20there%20are%20multiple%20features%20we%20may%20want%20to%20consider%20or%20alternate%20solutions%5D%0A%0AFeature%20Description%3A%20%5BPlease%20tell%20us%20how%20you%20envision%20your%20feature%20working%5D%0A%0AContextual%20Information%3A%20%5BPlease%20provide%20any%20notes%20or%20contextual%20information%20here%5D%0A%0A%0A"
    if (!window['jiraIssueCollector']) {
        setCollector()
        window['jiraIssueCollector'] = this
    }

    return (
        <BottomNavigation showLabels className={ classes.root }>
            <Typography>
                The AMIC Data Hub is a Minimum Viable Product (MVP) release.
                Please provide feedback on features or issues via the "Give Feedback" button to the right.
            </Typography>
            <BottomNavigationAction href={mailtoLink} id="feedbackTrigger" label="Give Feedback" icon={ <FeedbackIcon /> } />

        </BottomNavigation>
    )
}