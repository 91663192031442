import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { red, blue } from '@material-ui/core/colors'
import { withRouter } from 'react-router-dom'



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const redTheme = createMuiTheme({ palette: { primary: red } })


export default withRouter(function AlertDialogSlide(props) {
    const [open, setOpen] = React.useState(true);

    const {slideTitle, slideMessage} = props

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        window.history.back();
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <MuiThemeProvider theme={redTheme}>

                    <DialogTitle id="alert-dialog-slide-title">{slideTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {slideMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Dismiss
                        </Button>
                    </DialogActions>
                </MuiThemeProvider>
            </Dialog>

        </div>
    );
}
)
