import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { IntegratedSearchAndNavLayout as Layout } from '../../layouts'
import { getGraphQLBackend } from '../../utilities/endpoints'
import ImprovedSearch from './components/ImprovedSearch'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { SearchkitProvider, SearchkitClient } from '@searchkit/client'

const REACT_APP_GRAPHQL_BACKEND = getGraphQLBackend()

const UploadPageStyle = {
    searchContainer: {
        flexGrow: 1,
    },
}

const client = new ApolloClient({
    uri: `${REACT_APP_GRAPHQL_BACKEND}`,
    cache: new InMemoryCache()
})

const skClient = new SearchkitClient()

class ImprovedSearchPage extends Component {

    constructor (props) {
        super(props)
        this.state = {}
    }

    render() {
        const { classes } = this.props

        return (
            <Layout>
                <ApolloProvider client={ client } >
                    <SearchkitProvider client={ skClient }>
                        <ImprovedSearch />
                    </SearchkitProvider>
                </ApolloProvider>
            </Layout>
        )
    }
}

ImprovedSearchPage.propTypes = {}
ImprovedSearchPage.defaultProps = {}

const mapStateToProps = state => ({})

export default withRouter(connect(mapStateToProps)(withStyles(UploadPageStyle)(ImprovedSearchPage)))

