// DISTRIBUTION STATEMENT C. Distribution authorized to U.S. Government
// agencies and their contractors; Critical Technology; June 2017. Other
// requests for this document shall be referred to U.S. Army CCDC GVSC.
//
// WARNING: This document contains technical data whose export is restricted by
// the Arms Export Control Act (Title 22, U.S.C., Sec 2751, et seq.) or the
// Export Administration Act of 1979 (Title 50, U.S.C., App. 2401 et seq.), as
// amended. Violations of these export laws are subject to severe criminal
// penalties. Disseminate in accordance with provisions of DoD Directive
// 5230.25. DESTRUCTION NOTICE: For unclassified, limited distribution
// documents, destroy by any method that will prevent disclosure of the
// contents or reconstruction of the document.
//
// This material is based upon work supported by the Department of the Army
// under Air Force Contract No. FA8702-15-D-0001. Any opinions, findings,
// conclusions or recommendations expressed in this material are those of the
// author(s) and do not necessarily reflect the views of the Department of the
// Army.
//
// Copyright 2020 Massachusetts Institute of Technology.
//
// The software/firmware is provided to you on an As-Is basis
//
// Delivered to the U.S. Government with Unlimited Rights, as defined in DFARS
// Part 252.227-7013 or 7014 (Feb 2014). Notwithstanding any copyright notice,
// U.S. Government rights in this work are defined by DFARS 252.227-7013 or
// DFARS 252.227-7014 as detailed above. Use of this work other than as
// specifically authorized by the U.S. Government may violate any copyrights
// that exist in this work.

import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import { getBackend } from '../utilities/endpoints'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import { fade } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import HomeIcon from '@material-ui/icons/Home'
import logoImage from '../app/amic_logo.png';
export { logoImage };

const drawerWidth = 240

const REACT_APP_BACKEND = getBackend()

const PageStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    root: {
        padding: theme.spacing(2),
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        //width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
})

class IntegratedSearchAndNavLayout extends PureComponent {

    constructor (props) {
        super(props)
        this.state = {
            open: false,
            anchorEl: null,
            mobileMoreAnchorEl: null,
            isMenuOpen: false,
            isMobileMenuOpen: false,
            menuId: 'primary-search-account-menu',
            mobileMenuId: 'primary-search-account-menu-mobile',
        }
        // this.handleClick = this.handleClick.bind(this);
        // this.handleClose = this.handleClose.bind(this);
    }

    handleClick(event) {
        this.setState({ anchorEl: event.currentTarget, open: Boolean(event.currentTarget), id: "simple-popover" })
    }

    handleClose(event) {
        this.setState({ anchorEl: event.currentTarget, open: false, id: undefined })
    }

    handleDrawerOpen = () => {
        this.setState({ open: true })
    };

    handleDrawerClose = () => {
        this.setState({ open: false })
    };

    handleProfileMenuOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    };

    handleMobileMenuOpen = (event) => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget })
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null })
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null })
        this.handleMobileMenuClose()
    };

    issueGeneralSearch = fullMatch => {
        console.log('issueGeneralSearch')
        const { history } = this.props
        const { searchUrl, queryText } = fullMatch
        history.push({
            pathname: '/search',
            search: searchUrl,
        })
    };

    issueSpecificSearch = ({ type, _id }) => {
        console.log('issueSpecificSearch')
        const { history } = this.props
        history.push({ pathname: `/${type}/${_id}` })
    };

    getFetchDataPromise(query) {
        return new Promise((res, rej) => {
            let items = []
            fetch(`${REACT_APP_BACKEND}/api/v1/search?queryText=${query}&limit=5`)
                .then(res => res.json())
                .then(
                    result => {
                        items = result.results.results
                    },
                    error => {
                        items = []
                    }
                ).then(() => {
                    res(items)
                })
        })
    }

    render() {
        const { classes, children, history } = this.props
        const { isMenuOpen, isMobileMenuOpen, open, menuId, mobileMenuId, anchorEl, mobileMoreAnchorEl } = this.state

        return (
            <div id="searchPage" className={ classes.grow } >
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={ clsx(classes.menuButton, open && classes.hide) }
                            color="inherit"
                            aria-label="open drawer"
                            onClick={ this.handleDrawerOpen }
                        >
                            <MenuIcon />
                        </IconButton>

                        <Typography className={ classes.title } variant="h6" noWrap>
                            <Button color="white" variant="inherit" onClick={ () => history.push('/') }>
                                <img src={logoImage} className="logoH" alt=""/><span className="amicBrand">AMIC</span> Data Hub
                            </Button>

                        </Typography>

                        <div className={ classes.grow } />

                        <div className={ classes.sectionDesktop }>
                            <Button color="primary" variant="inherit" href="/logout">
                                Logout
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>

                <Drawer
                    className={ classes.drawer }
                    variant="persistent"
                    anchor="left"
                    open={ open }
                    classes={ {
                        paper: classes.drawerPaper,
                    } }
                >
                    <div className={ classes.drawerHeader }>
                        <IconButton onClick={ this.handleDrawerClose }>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <ListItem button color="primary" onClick={ () => history.push('/') } key="Home">
                            <ListItemIcon> <HomeIcon /> </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem button color="primary" onClick={ () => history.push('/upload') } key="Upload a Dataset">
                            <ListItemIcon> <CloudUploadIcon /> </ListItemIcon>
                            <ListItemText primary="Upload a Dataset" />
                        </ListItem>
                    </List>
                    <Divider />
                </Drawer>

                <Menu
                    anchorEl={ anchorEl }
                    getContentAnchorEl={ null }
                    anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
                    id={ menuId }
                    keepMounted
                    transformOrigin={ { vertical: 'top', horizontal: 'right' } }
                    open={ isMenuOpen }
                    onClose={ this.handleMenuClose }
                >
                    <MenuItem onClick={ this.handleMenuClose }>Profile</MenuItem>
                    <MenuItem onClick={ this.handleMenuClose }>My account</MenuItem>
                </Menu>

                <div className={ classes.root }>
                    { children }
                </div>

            </div>
        )
    }

}

IntegratedSearchAndNavLayout.propTypes = {}

IntegratedSearchAndNavLayout.defaultProps = {}

export default withRouter(withStyles(PageStyles)(IntegratedSearchAndNavLayout))
