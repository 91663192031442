import { gql } from '@apollo/client'
import { useSearchkitQuery } from '@searchkit/client'
import { HitsList, HitsGrid } from './Hits'
import {
    FacetsList,
    SearchBar,
    Pagination,
    ResetSearchButton,
    SelectedFilters,
} from '@searchkit/elastic-ui'
import '@elastic/eui/dist/eui_theme_light.css'

import React, { useState } from 'react'


import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageContentHeader,
    EuiPageContentHeaderSection,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiPageSideBar,
    EuiTitle,
    EuiHorizontalRule,
    EuiButtonGroup,
    EuiFlexGroup
} from '@elastic/eui'

const query = gql`
    query resultSet($query: String, $filters: [SKFiltersSet], $page: SKPageInput) {
        results(query: $query, filters: $filters) {
            summary {
                total
                appliedFilters {
                    id
                    identifier
                    display
                    label
                    ... on DateRangeSelectedFilter {
                        dateMin
                        dateMax
                    }
                    ... on NumericRangeSelectedFilter {
                        min
                        max
                    }
                    ... on ValueSelectedFilter {
                        value
                    }
                }
                query
            }
            hits(page: $page) {
                page {
                    total
                    totalPages
                    pageNumber
                    from
                    size
                }
                items {
                    id
                    ... on ResultHit {
                        fields {
                        project
                        tags
                        description
                        dataset_name
                        sub_project
                        _collection
                        activity
                        activity_location
                        vehicle
                        createdAt
                        createdBy
                        }
                    }
                }
            }
            facets {
                identifier
                type
                label
                display
                entries {
                    id
                    label
                    count
                }
            }
        }
    }
`

export default function ImprovedSearch() {

    var { data, loading } = useSearchkitQuery(query)
    const [viewType, setViewType] = useState('list')
    const Facets = FacetsList([])

    try {
        let temp = data.results.summary.total
    } catch (err) {
        data = null
    }

    return (
        < EuiPage >
            <EuiPageSideBar>
                <SearchBar loading={ loading } />
                <EuiHorizontalRule margin="m" />
                <Facets data={ data?.results } loading={ loading } />
            </EuiPageSideBar>
            <EuiPageBody component="div">
                <EuiPageHeader>
                    <EuiPageHeaderSection>
                        <EuiTitle size="l">
                            <SelectedFilters data={ data?.results } loading={ loading } />
                        </EuiTitle>
                    </EuiPageHeaderSection>
                    <EuiPageHeaderSection>
                        <ResetSearchButton loading={ loading } />
                    </EuiPageHeaderSection>
                </EuiPageHeader>
                <EuiPageContent>
                    <EuiPageContentHeader>
                        <EuiPageContentHeaderSection>
                            <EuiTitle size="s">
                                <h2>{ data?.results.summary.total } Results</h2>
                            </EuiTitle>
                        </EuiPageContentHeaderSection>
                        <EuiPageContentHeaderSection>
                            <EuiButtonGroup
                                legend=""
                                options={ [
                                    {
                                        id: `grid`,
                                        label: 'Grid'
                                    },
                                    {
                                        id: `list`,
                                        label: 'List'
                                    }
                                ] }
                                idSelected={ viewType }
                                onChange={ (id) => setViewType(id) }
                            />
                        </EuiPageContentHeaderSection>
                    </EuiPageContentHeader>
                    <EuiPageContentBody>
                        { viewType === 'grid' ? <HitsGrid data={ data } /> : <HitsList data={ data } /> }
                        <EuiFlexGroup justifyContent="spaceAround">
                            <Pagination data={ data?.results } />
                        </EuiFlexGroup>
                    </EuiPageContentBody>
                </EuiPageContent>
            </EuiPageBody>
        </EuiPage >
    )
}
